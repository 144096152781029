<template>
  <section class="py-10 lg:py-20 bg-gray-50">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto">
        <div class="mb-10">
          <a class="text-3xl font-bold leading-none" href="#">
            <img class="h-12 mx-auto" src="atis-assets/logo/atis/atis-mono-black.svg" alt="" width="auto">
          </a>
        </div>
        <div class="mb-6 lg:mb-10 p-6 lg:p-12 bg-white shadow rounded text-center">
          <div class="mb-6">
            <h3 class="text-2xl font-bold">Forgot Password</h3>
          </div>
          <form action="">
            <div class="mb-3 flex p-4 bg-gray-50 rounded">
              <input v-model="username" class="w-full text-xs bg-gray-50 outline-none" type="text" placeholder="Enter your Username">
              <svg class="h-6 w-6 ml-4 my-auto text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
            </div>
            <div class="text-center">
              <button @click="requestPasswordReset()" class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Request Password Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ForgotPassword',
  data () {
    return {
      username: null,
    }
  },
    methods: {
    async requestPasswordReset() {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users/request-password-reset`, {
        username: this.username,
        origin: document.location.origin,
      })

      console.log(response)

      if (response.status === 200) {
        this.$router.push({ name: 'Login', query: { fromForgotPassword: true } })
      }

    },
  },
}
</script>


// TODO Delelte oofline history on logout